
const menus=[
    {
        id:1,
        parent_id:null,
        title:'首页',
        name:'House',
        icon:'House',
        url:'/index',
        childrens:[]
    },
    {
        id:2,
        parent_id:null,
        title:'角色管理',
        name:'User',
        icon:'User',
        url:'',
        childrens:[
            {id:3, title:'开发商管理',name:'devList',parent_id:2, url:'/devManage/devList',childrens:[]},
            {id:4, title:'经销商管理',name:'selList',parent_id:2, url:'/person/selList',childrens:[]},
            {id:5, title:'农户管理',name:'nohList',parent_id:2, url:'/person/nohList',childrens:[]},
        ]
    },
    {
        id:6,
        parent_id:null,
        title:'划转管理',
        name:'Compass',
        icon:'Compass',
        url:'',
        childrens:[
            {id:7, title:'租金配置',name:'rentList',parent_id:6, url:'/person/devList',childrens:[]},
            {id:8, title:'类型列表',name:'rovList',parent_id:6, url:'/person/selList',childrens:[]},
        ]
    },
    {
        id:9,
        parent_id:null,
        title:'系统管理',
        name:'Setting',
        icon:'Setting',
        url:'',
        childrens:[
            {id:10, title:'菜单管理',name:'menuList',parent_id:9, url:'/person/devList',childrens:[]},
            {id:11, title:'全局配置',name:'allSet',parent_id:9, url:'/person/selList',childrens:[]},
        ]
    }
]
const projectName='api';
const USERTYPE=[
    {type:1,name:'管理员',title:'素质教育服务平台',userType:'ADMIN',table:'e2_admin'},
    {type:2,name:'学校',title:'素质教育SaaS管理系统',userType:'SCHOOL',table:'e2_school'},
    {type:3,name:'服务商',title:'服务商',userType:'BASE',table:'e2_baze'},
    {type:4,name:'学校教师',title:'课外淘（教师）',userType:'SCHOOL_TEACHER',table:'e2_teacher'},
    {type:5,name:'商户教师',title:'课外淘（教师）',userType:'BASE_TEACHER',table:'e2_teacher'},
    {type:6,name:'学生',title:'课外淘（学生）',userType:'STUDENT',table:'e2_student'},
    {type:7,name:'运营商',title:'运营商',userType:'OPERATOR',table:'e2_operator'},
    {type:8,name:'课程方',title:'课外淘',userType:'COURSETOR',table:'e2_coursetor'},
    {type:9,name:'场地方',title:'课外淘',userType:'FIELDTOR',table:'e2_fieldtor'},
    {type:10,name:'师培方',title:'课外淘',userType:'TEACHERTOR',table:'e2_teachertor'},
]
const STATELIST=[{type:0,name:'待提交审核'},{type:1,name:'待审核'},{type:2,name:'审核通过'},{type:3,name:'审核失败'}]
const BASETYPE=[
    {type:1,name:'活动类'},{type:2,name:'教培类'},{type:3,name:'咨询类'},{type:4,name:'托管类'},{type:5,name:'赛事类'},{type:6,name:'演艺类'},{type:7,name:'会务类'}
]
const COURSELIST=[
    {type:1,name:'工业劳动教育'},{type:2,name:'服务业劳动教育'},{type:3,name:'研学实践活动'}
]
const COURSECOM=[
    {value:1,label:'采矿业',
        children:[
        {value:101,label:'烟煤和无烟煤的开采洗选'},{value:101,label:'褐煤的开采洗选'},{value:101,label:'其他煤炭采选'},
        {value:101,label:'天然原油和天然气开采'},{value:101,label:'与石油和天然气开采有关的服务注动'},{value:101,label:'铁矿采选'},
        {value:101,label:'其他黑色金属矿采选'},{value:101,label:'常用有色金属矿采选'}
        ]
    }
]
const ACTIVETYPE=[
    {type:1,name:'学校'},{type:2,name:'家庭'}
]
const SETTYPE=[
    {name:'团队'},{name:'个人'}
]
const ACTIVESTATETYPE=[
    {type:1,name:'上架'},{type:0,name:'下架'}
]
const ACTIVELIST=['艺术活动','体育活动','劳动教育','研学实践','综合实践','大众活动','亲子活动','祖孙活动'];
const CommentList=[
    {code:'hj_value', name:'建设值', max_value:5, descArr:['杂乱','有序','干净','舒适','优美']},
    {code:'jx_value', name:'教学值', max_value:5, descArr:['欠佳','合格','良好','优秀','专业']},
    {code:'gl_value', name:'管理值', max_value:5, descArr:['散乱','松散','有序','规范','科学']},
    {code:'fw_value', name:'服务值', max_value:5, descArr:['缺失','怠慢','一般','良好','周到']},
    {code:'cx_value', name:'创新值', max_value:5, descArr:['普通','略有','不错','独特','新奇']},
    {code:'cp_value', name:'产品值', max_value:5, descArr:['瑕疵', '普通','良品','优质','精良']},
    {code:'xf_value', name:'消费值', max_value:5, descArr:['不值','小贵','合适','推荐','超值']},
]
const PAY_STATE=[{value:0,label:'待支付',btnText:'去付款'},{value:1,label:'已支付',btnText:'去使用'},{value:2,label:'已退款',btnText:''}]
const STUDY_LEVEL=[{value:0,label:'全年段',gradeArr:[1,2,3,4,5,6,7,8,9,10,11,12]},{value:1,label:'小学',gradeArr:[1,2,3,4,5,6]},
    {value:2,label:'初中',gradeArr:[7,8,9]},{value:3,label:'高中',gradeArr:[10,11,12]},{value:4,label:'小学-初中',gradeArr:[1,2,3,4,5,6,7,8,9]},
    {value:5,label:'初中-高中',gradeArr:[7,8,9,10,11,12]},{value:6,label:'小学和高中',gradeArr:[1,2,3,4,5,6,10,11,12]},{value:7,label:'大众',gradeArr:[-2,-1,0]}] //学段

const INSUREGOODSTYPELIST=[
    {ins_type:1000,name:'交通'},{ins_type:2000,name:'旅游'},{ins_type:3000,name:'酒店'},{ins_type:4000,name:'组合'}
]
export {
    menus, projectName, USERTYPE, STATELIST, BASETYPE, COURSELIST, ACTIVETYPE,COURSECOM,
    CommentList, ACTIVELIST, PAY_STATE, STUDY_LEVEL, SETTYPE, ACTIVESTATETYPE,INSUREGOODSTYPELIST
}
