import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { base64ToFileNoAtob, isImage, numberToChinese, showImage } from "@/utils/common";
import html2canvas from 'html2canvas';
import ImageShow2 from "@/components/ImageShow2.vue";
import { collect_active, save_report_img } from "@/api/api";
export default {
  name: "reportDetails",
  components: {
    ImageShow2
  },
  data() {
    return {
      actionList: [{
        label: '智慧力',
        value: require('@/static/img/material/medal-1.png'),
        bgc: '#E24040',
        schedule: 0
      }, {
        label: '匠心力',
        value: require('@/static/img/material/medal-2.png'),
        bgc: '#EC5003',
        schedule: 0
      }, {
        label: '创新力',
        value: require('@/static/img/material/medal-3.png'),
        bgc: '#FBAE00',
        schedule: 0
      }, {
        label: '生存力',
        value: require('@/static/img/material/medal-4.png'),
        bgc: '#367341',
        schedule: 0
      }, {
        label: '合作力',
        value: require('@/static/img/material/medal-5.png'),
        bgc: '#04A887',
        schedule: 0
      }, {
        label: '公民力',
        value: require('@/static/img/material/medal-6.png'),
        bgc: '#1E82B6',
        schedule: 0
      }, {
        label: '领导力',
        value: require('@/static/img/material/medal-7.png'),
        bgc: '#5F098D',
        schedule: 0
      }],
      actionListMapper: {
        '智慧力': require('@/static/img/material/medal-1.png'),
        '匠心力': require('@/static/img/material/medal-2.png'),
        '创新力': require('@/static/img/material/medal-3.png'),
        '生存力': require('@/static/img/material/medal-4.png'),
        '合作力': require('@/static/img/material/medal-5.png'),
        '公民力': require('@/static/img/material/medal-6.png'),
        '领导力': require('@/static/img/material/medal-7.png')
      },
      maxValue: 1000,
      report: null,
      // 勋章加分分析
      optionEvaElementValues: [],
      // 勋章总积分
      totalPoints: 0,
      params: {},
      loadingState: true
    };
  },
  created() {
    const {
      osid
    } = this.$route.query;
    const [oid, sid] = (osid || '').split('allisi');
    if (!oid && !sid) {
      return;
    }
    const params = {
      operator_server_active_id: oid,
      student_id: sid
    };
    this.params = params;
    collect_active(params).then(res => {
      this.report = res.data;
      this.updateGroup();
      this.optionEvaElementValuesGenerate();
      this.eventInformationHandler();
      this.$nextTick(() => {
        setTimeout(() => {
          this.downLoadReport();
        }, 1000);
      });
    });
  },
  mounted() {
    document.title = '活动报告';
  },
  methods: {
    isImage,
    showImage,
    numberToChinese,
    eventInformationHandler() {
      var _this$report;
      const courseList = ((_this$report = this.report) === null || _this$report === void 0 || (_this$report = _this$report.allData) === null || _this$report === void 0 ? void 0 : _this$report.courseList) || [];
      courseList.map(iter => {
        iter.launch = true;
      });
      this.report.allData.courseList = courseList;
    },
    optionEvaElementValuesGenerate() {
      var _this$report2;
      const countStringOccurrences = arr => {
        const counts = {};
        arr.forEach(item => {
          if (counts[item]) {
            counts[item] += 1;
          } else {
            counts[item] = 1;
          }
        });
        return counts;
      };
      const fun = str => {
        if (typeof str !== 'string') {
          return '';
        }
        return str.replace(/^\[\s*|\s*\]$/g, '').split('][');
      };
      const optionEvaElementValues = (_this$report2 = this.report) === null || _this$report2 === void 0 || (_this$report2 = _this$report2.allData) === null || _this$report2 === void 0 ? void 0 : _this$report2.optionEvaElementValues;
      optionEvaElementValues.forEach(iter => {
        const evaOptionValue = countStringOccurrences(fun(iter.evaOptionValue));
        const currPoints = (() => Object.values(evaOptionValue).reduce((accumulator, currentValue) => accumulator + currentValue, 0))();
        this.totalPoints += currPoints;
        this.optionEvaElementValues.push({
          label: iter.evaOptionName,
          value: this.actionListMapper[iter.evaOptionName],
          currPoints: currPoints,
          evaOptionValue: evaOptionValue
        });
      });
    },
    updateGroup() {
      var _this$report3, _this$report4;
      const evaRadarNameArr = (_this$report3 = this.report) === null || _this$report3 === void 0 || (_this$report3 = _this$report3.allData) === null || _this$report3 === void 0 ? void 0 : _this$report3.evaRadarNameArr;
      const evaRadarValueArr = (_this$report4 = this.report) === null || _this$report4 === void 0 || (_this$report4 = _this$report4.allData) === null || _this$report4 === void 0 ? void 0 : _this$report4.evaRadarValueArr;
      evaRadarNameArr.forEach((iter, index) => {
        const findIndex = this.actionList.findIndex(item => item.label === iter);
        if (findIndex !== -1) {
          this.actionList[findIndex].schedule = evaRadarValueArr[index];
        }
      });
    },
    valueMapper(value) {
      // 假设的全局最大绝对值
      const MAX_ABSOLUTE_VALUE = this.maxValue;

      // 检查是否为null或undefined
      if (value === null || value === undefined) {
        return 0;
      }

      // 检查是否为负数
      if (value < 0) {
        return 0;
      }

      // 计算归一化的值
      const normalizedValue = value / MAX_ABSOLUTE_VALUE * 145;

      // 确保结果不超出0~260的范围
      return Math.min(145, Math.max(0, normalizedValue));
    },
    groupStyle({
      bgc,
      schedule
    }) {
      const maxValues = [];
      this.actionList.forEach(iter => {
        maxValues.push(iter.schedule);
      });
      this.maxValue = Math.max(...maxValues);
      return {
        backgroundColor: bgc,
        height: `${this.valueMapper(schedule)}px`
      };
    },
    downLoadReport() {
      html2canvas(document.getElementById('pageItem'), {}).then(canvas => {
        const link = canvas.toDataURL("image/jpg");
        const formData = new FormData();
        formData.append('file', base64ToFileNoAtob(link));
        formData.append('operator_server_active_id', this.params.operator_server_active_id);
        formData.append('student_id', this.params.student_id);
        save_report_img(formData).then(() => {
          this.loadingState = false;
        });
      });
    }
  }
};